import React from 'react'
import SEO from '../components/Blocks/SEO'
import Addiction from '../components/Pages/Addiction'

function AddictionPage(props) {
  return (
    <React.Fragment>
      <SEO
        title="Spelproblem"
        description="Information om spelproblem, hur du kan upptäcka det och vart du kan vända dig för att få hjälp."
      />
      <Addiction />
    </React.Fragment>
  )
}

export default AddictionPage
