import React from 'react'
import styles from './Addiction.module.css'
import Page from '../Layout/Page'
import Header from '../Layout/Header'
import Hero from '../Blocks/Hero'
import Content from '../Blocks/Content'

function Addiction(props) {
  return (
    <React.Fragment>
      <Header>
        <Hero main heading="Spelproblem">
          <p className={styles.preamble}>
            Påverkar ditt eller någon anhörigs spelande livet på ett negativt
            sätt? Det kan vara ett tecken på spelproblem. På den här sidan
            finner du information om spelproblem, hur du kan upptäcka det och
            vart du kan vända dig för att få hjälp.
          </p>
        </Hero>
      </Header>
      <Page>
        <article>
          <Content>
            <h2>Om Spelproblematik</h2>
            <p>
              Enligt ett{' '}
              <a
                className={styles.link}
                href="https://www.folkhalsomyndigheten.se/contentassets/357b2c2438824cebbfbd1050e3fa4e27/spelproblem-hur-vanligt-ar-det.pdf"
              >
                faktablad från Folkhälsoinstitutet
              </a>{' '}
              kunskapsstöd förebygga spelproblem, har ungefär 2% av den svenska
              befolkningen problem med sitt spelande. Spelproblem förekommer i
              alla befolkningsgrupper men är enligt Folkhälsoinsitutet vanligast
              hos fyra utmärkande grupper som kategoriseras som personer med
              psykisk ohälsa, män som riskkonsumerar alkohol, personer som haft
              socialbidrag och personer som utsatts för våld och hot om våld.
            </p>
            <p>
              Det är inte bara den som spelar som drabbas när den spelar för
              mycket. Det kan även ge allvarliga konsekvenser för familj, vänner
              och samhället i stort.
            </p>
            <h3>Spelproblem, Spelmissbruk eller Hasardspelsyndrom</h3>
            <p>
              Problem med spel om pengar kan ha flera olika benämningar.
              Benämningarna kan avse olika saker och kan användas olika beroende
              på vilket sammanhang ordet används i. Vad som är gemensamt för
              alla är att de på ett eller annat sätt syftar till att den person
              som spelar har svårt att kontrollera sitt spelande.
            </p>
            <p>
              <strong>Spelproblem</strong>, syftar inom forskning ofta på att en
              individ har så pass dålig kontroll på sitt spelande att det leder
              till negativa konsekvenser.
            </p>
            <p>
              <strong>Spelmissbruk</strong>, är ingen formell definition men
              syftar ofta till att beskriva negativa, sociala konsekvenser till
              följd av spel.
            </p>
            <p>
              <strong>Hasardspelsyndrom</strong>, även kallat spelberoende är
              ett psykiatriskt tillstånd och namnet på den kliniska diagnos som
              läkare och psykologer använder för att diagnostisera spelberoende.
              Det finns enligt DSM-5, nio stycken diagnoskriterier för
              Hasardspelsyndrom. Stämmer fyra eller fler av dessa kriterier är
              det möjligt, men inte definitivt, att individen har diagnosen. Du
              kan läsa mer om dessa kriterier på{' '}
              <a
                className={styles.link}
                href="https://www.1177.se/sjukdomar--besvar/psykiska-sjukdomar-och-besvar/beroende-och-missbruk/hasardspelsyndrom--spelberoende/"
              >
                1177
              </a>
            </p>
            <h2>Upptäcka spelproblem</h2>
            <p>
              Det är inte alltid enkelt att upptäcka spelproblem. Då alla är
              olika finns det inget exakt facit på hur spelproblem kan
              upptäckas. Enligt stödlinjen.se finns det dock några faktorer som
              kan tyda på ett eventuellt spelberoende:
            </p>
            <ul>
              <li>
                Saker som tidigare har varit viktiga prioriteras bort till
                fördel för spelande.
              </li>
              <li>Pengar försvinner oförklarligt.</li>
              <li>Lånar pengar som inte kan betalas tillbaka.</li>
              <li>Ekonomiska problem trots god inkomst.</li>
              <li>
                Kan verka frånvarande samtidigt som mer tid läggs på spel.
              </li>
              <li>Isolerar sig från anhöriga och vänner.</li>
              <li>Verkar nedstämd, orolig eller lättretlig.</li>
            </ul>
            <h2>Stöd och hjälp</h2>
            <p>
              Det är via kommun och landsting möjligt att få professionell hjälp
              med stöd och behandling för att bli kvitt ett spelberoende. Se
              information på{' '}
              <a
                className={styles.link}
                href="https://www.stodlinjen.se/#!/information-om-spelberoende/anhorig"
              >
                stödlinjen.se
              </a>
            </p>
          </Content>
        </article>
      </Page>
    </React.Fragment>
  )
}

export default Addiction
